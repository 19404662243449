import React from 'react';
import imgBanner from '../../public/images/banner/banner-three-bg.png';

const Banner = ({ children, title, cls, baner__thumb = null }) => {
  return (
    <section
      style={{
        backgroundImage: `url(${imgBanner})`,
      }}
      className={`banner clear__top  ${cls}`}
    >
      <div className='container'>
        <div className='banner__area'>
          <h1 className='neutral-top'>{title}</h1>
          {children}
        </div>
      </div>
      {baner__thumb == null ? (
        ' '
      ) : (
        <img src={baner__thumb} alt='blog__thumb' className='banner__thumb' />
      )}
    </section>
  );
};

export default Banner;
