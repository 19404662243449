import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import Banner from '../../components/common/Banner';
import Breadcrumb from '../../components/common/Breadcrumb';
import baner__thumb from '../../public/images/banner/key-illustration.png';

/**
 * Single page (/:slug)
 *
 * This file renders a single page and loads all the content.
 *
 */
const Page = ({ data, location }) => {
  const page = data.ghostPage;

  return (
    <>
      <MetaData data={data} location={location} type='website' />
      <Helmet>
        <style type='text/css'>{`${page.codeinjection_styles}`}</style>
      </Helmet>
      <Layout>
        <Banner
          title={page.title}
          cls=' banner--secondary'
          baner__thumb={baner__thumb}
          img={page.feature_image}
        >
          <Breadcrumb
            breadcrumbs={[
              ['Home', '/'],

              [page.title, '#'],
            ]}
          />
        </Banner>

        {/* The main post content */}
        <section class='terms section__space'>
          <div class='container'>
            <div class='terms-area'>
              <section
                className='content-body load-external-scripts'
                dangerouslySetInnerHTML={{ __html: page.html }}
              />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

Page.propTypes = {
  data: PropTypes.shape({
    ghostPage: PropTypes.shape({
      codeinjection_styles: PropTypes.object,
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

export default Page;

export const postQuery = graphql`
  query ($slug: String!) {
    ghostPage(slug: { eq: $slug }) {
      ...GhostPageFields
    }
  }
`;
